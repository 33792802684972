import React from "react";
import ContactForm from "../components/ContactForm";
import DefaultLayout from "../layouts/DefaultLayout";
import { PageProps } from "gatsby";

const Page: React.FC<PageProps> = ({ location }) => (
  <DefaultLayout pathname={location.pathname} pageTitle="Contact Us">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <h2 className="mb-3">Contact Details</h2>
          <div className="card mb-5">
            <div className="card-body">
              <p>
                <strong>Address</strong>
                <br />
                38-40 Aldenhoven Road
                <br />
                Lonsdale, SA 5160
              </p>
              <p>
                <strong>Phone</strong>
                <br />
                <a href="tel:+61881861733">08 8186 1733</a>
              </p>
              <p>
                <strong>Email</strong>
                <br />
                <a href="mailto:sales@woodndoors.com">sales@woodndoors.com</a>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <h2 className="mb-3">Enquiry Form</h2>
          <div>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
);

export default Page;
